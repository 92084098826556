import { useEffect, useState } from 'react';
import Card from '../card/Card';
import { getOltSessions } from '../../utils/vqc';

export default function OltSessions () {
  const [olts, setOlts] = useState([]);

  useEffect(() => {
    getData();
    setInterval(getData, 30000);
  }, []);

  const getData = async () => {
    let data = await getOltSessions();
    data = Object.values(data);
    data = data.filter(a => a.connections > 0);
    data.sort((a, b) => b.connections - a.connections);
    setOlts(data);
  }

  const getTableRows = () => {
    let rows = olts.map((olt, i) => {
      return <tr key={`olt-session-key-${olt.clli}`}>
        <td>{olt.clli}</td>
        <td>{olt.ip}</td>
        <td>{olt.connections}</td>
      </tr>;
    });
    return rows;
  }

  let content = <h3>No active sessions.</h3>;
  if (olts.length) {
    content = <table className='table table-dark table-striped table-hover'>
      <thead>
        <tr>
          <td>CLLI</td>
          <td>IP</td>
          <td>Connections</td>
        </tr>
      </thead>
      <tbody>
        {getTableRows()}
      </tbody>
    </table>;
  }
  
  return (
    
    <Card className={'top-10 olt-sessions'}>
      <header>Active OLT Sessions</header>
      <section>
        { content }
      </section>
    </Card>
  )
}

