import axios from "axios";
import { getAccessToken } from "./auth";
const sessionId = 'psiconsole';
const techId = 'psiconsole';

export const prefetchOntDiagnostics = async (sn) => {
  const token = await getAccessToken()
  let r = await axios.get(`https://ont.api.fybrlabs.frontier.com/Prod/v2/triggerprefetch/${sn}?inclAddress=false&uuid=${sessionId}&techId=${techId}`, {headers: { 'auth-token': token }});
  return r.data;
}

export const getOntDiagnostics = async (sn) => {
  const token = await getAccessToken()
  let r = await axios.get(`https://ont.api.fybrlabs.frontier.com/Prod/v2/getontdiags/${sn}?uuid=${sessionId}&techId=${techId}`, {headers: { 'auth-token': token }});
  return r.data;
}

export const getOntLastSnapshot = async (sn) => {
  const token = await getAccessToken()
  let r = await axios.get(`https://ont.api.fybrlabs.frontier.com/Prod/v2/${sn}?uuid=${sessionId}&techId=${techId}`, {headers: { 'auth-token': token }});
  return r.data;
}
